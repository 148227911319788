import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";

import "@/assets/scss/main.scss";

Vue.config.productionTip = false

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_OLD_SERVER_BASE_URL;
//Vue.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import {
    VueReCaptcha
} from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_CLIENT_V3
})

import VueRouter from 'vue-router';

Vue.use(VueRouter);

// lazy loading the vue components
// 舊版首頁
//const PageHomeContent = () => import('@/components/Pages/PageHomeContent.vue');

const PageHomeAnalytic = () => import('@/components/Pages/PageHomeAnalytic.vue');
const PagePricing = () => import('@/components/Pages/PagePricing.vue');

const PagePurchase = () => import('@/components/Pages/PagePurchase.vue');

/*
const PageTestTapPay = () => import('@/components/Pages/PageTestTapPay.vue');
*/

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: PageHomeAnalytic,
        },
        {
            path: '/Product/Pricing',
            component: PagePricing,
        },
        {
            path: '/Product/Purchase',
            component: PagePurchase,
        },
        /*{
            path: '/Test/TapPay',
            component: PageTestTapPay,
        },*/
    ]
});

Vue.use(VueGtag, {
    config: {
        id: "UA-136510434-1"
    },
    includes: [{
        id: 'AW-608742370'
    },]
}, router);

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
    'zh-Hant': {
        element_header: require('@/locales/element_header_zh-hant.json'),
        //element_content: require('@/locales/element_content_zh-hant.json'),
        element_content_analytic: require('@/locales/element_content_analytic_zh-hant.json'),
        element_footer: require('@/locales/element_footer_zh-hant.json'),
        element_banner: require('@/locales/element_banner_zh-hant.json'),
        element_lexProgress: require('@/locales/element_lexProgress_zh-hant.json'),
    },
    'zh-Hans': {
        element_header: require('@/locales/element_header_zh-hans.json'),
        //element_content: require('@/locales/element_content_zh-hans.json'),
        element_content_analytic: require('@/locales/element_content_analytic_zh-hans.json'),
        element_footer: require('@/locales/element_footer_zh-hans.json'),
        element_banner: require('@/locales/element_banner_zh-hans.json'),
        element_lexProgress: require('@/locales/element_lexProgress_zh-hans.json'),
    },
    'vi-VN': {
        element_header: require('@/locales/element_header_vi-VN.json'),
        //element_content: require('@/locales/element_content_vi-VN.json'),
        element_content_analytic: require('@/locales/element_content_analytic_vi-VN.json'),
        element_footer: require('@/locales/element_footer_vi-VN.json'),
        element_banner: require('@/locales/element_banner_vi-VN.json'),
        element_lexProgress: require('@/locales/element_lexProgress_vi-VN.json'),
    }
};

const i18n = new VueI18n({
    locale: 'zh-Hant', // set locale
    messages, // set locale messages
});

new Vue({
    i18n,
    router,
    render: h => h(App),
}).$mount('#app')


