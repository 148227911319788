<template>
    <div id="app">
        <page-header :user="user" :show-search-box="false"></page-header>
        <main role="role">
            <!-- 主頁內容都放在 main 裡面-->
            <router-view :user="user"></router-view>
        </main>
        <page-footer></page-footer>
    </div>
</template>

<script>
    import appMixin from '@/lib/AppBaseMixin.js';

    import PageHeader from '@/components/Elements/PageHeader.vue';
    import PageFooter from '@/components/Elements/PageFooter.vue';
    
    //import HelloWorld from '@/components/HelloWorld.vue';
    //import VFacebookLogin from 'vue-facebook-login-component'

    export default {
        name: 'App',
        mixins: [appMixin],
        components: {
            PageHeader,
            PageFooter,
        },
        data: function () {
            return {
            };
        },
        methods: {
        },
        mounted: function () {
        },
    }
</script>
<style>
</style>
